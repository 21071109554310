import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { AuthContext } from "../../context/Auth/AuthContext";
import { green } from "@material-ui/core/colors";
import { Button, Select, MenuItem, CircularProgress, makeStyles, Dialog, DialogActions, DialogContent, DialogTitle,  } from '@material-ui/core';

//Design do modal e formulario
const useStyles = makeStyles(theme => ({
  root: {
          display: "flex",
          flexWrap: "wrap",
  },
  multFieldLine: {
          display: "flex",
          "& > *:not(:last-child)": {
                  marginRight: theme.spacing(1),
          },
  },
  btnWrapper: {
          position: "relative",
  },
  buttonProgress: {
          color: green[500],
          position: "absolute",
          top: "50%",
          left: "50%",
          marginTop: -12,
          marginLeft: -12,
  },
  formControl: {
          margin: theme.spacing(1),
          minWidth: 120,
  },
  colorAdorment: {
          width: 20,
          height: 20,
  },
  select: {width: "100%"},
}));

const KanbanSelectModal = ({ open, onClose, tagId, reload }) => {
  const classes = useStyles();
  const [kanbanlist, setKanbanList] = useState([]);
  
  const fetchKanbanList = useCallback(async () => {
     try {
       const { data } = await api.get("/kanbans/");
         setKanbanList(data.kanbans);
       } catch (err) {
         toastError(err);
     }
  });
  
const { user } = useContext(AuthContext);

const handleSaveTag = async (values, ticketId) => {
  console.log("handleSaveTag called with:", { values, ticketId });

  try {
    const response = await api.put(`/tickets/kanbans/${ticketId}`, { values, userId: user.id });
    toast.success('CRM saved successfully!');
    return response.data;
  } catch (error) {
    console.error("Error saving CRM:", error);
    toast.error('Error saving CRM');
    throw error;
  }
};

  useEffect(() => {fetchKanbanList();}, [])
  return (
    <div className={classes.root}>
    <Dialog
            open={open}
            onClose={onClose}
            maxWidth="xs"
            fullWidth
            scroll="paper">
            <DialogTitle id="form-dialog-title">
                    {(tagId ? "Selecione o novo CRM" : "Selecione o novo CRM")}
            </DialogTitle>
    <Formik
      initialValues={{ kanbanid: '' }}
      validationSchema={Yup.object({
        kanbanid: Yup.string().required('Required'),
      })}
      onSubmit={(values, actions) => {
        handleSaveTag(values, tagId).then(() => {
          actions.setSubmitting(false);
          onClose(); // Fechar o modal após a submissão
          if (typeof reload == 'function') {
            reload();
          }
        }).catch(error => {
          toast.error(error.message);
          actions.setSubmitting(false);
        });
      }}
    >
      {formik => (
        <Form>
          <DialogContent dividers>
          <Field as={Select} name="kanbanid">
            {kanbanlist.map(kanbanitem => (
              <MenuItem key={kanbanitem.id} value={kanbanitem.id}>
                {kanbanitem.name}
              </MenuItem>
            ))}
          </Field>
          </DialogContent>
          <DialogActions>          
          <Button type="submit" disabled={formik.isSubmitting}>
            {formik.isSubmitting ? <CircularProgress size={24} /> : 'Submit'}
          </Button>
          </DialogActions>
        </Form>
      )}
    </Formik>
    </Dialog>
    </div>
  );
};

export default KanbanSelectModal;

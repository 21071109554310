import React, { useState, useEffect, useContext, useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import api from "../../services/api";
import { AuthContext } from "../../context/Auth/AuthContext";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { toast } from "react-toastify";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import KanbanSelectModal from "../../components/KanbanSelectModal";
import ObservationsModal from "../../components/ObservationsModal";
import useTicketNotes from "../../hooks/useTicketNotes";
import EditNoteIcon from '@mui/icons-material/EditNote';
import { blue } from "@mui/material/colors";
import KanbanMessageSendModal from "../../components/KanbanMessageSendModal";
import TagMessageSendModal from "../../components/TagMessageSendModal";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(1),
    alignItems: "center",
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    marginBottom: theme.spacing(2),
    zIndex: 1000,
  },
  button: {
    background: "#10a110",
    border: "none",
    padding: "10px",
    color: "white",
    fontWeight: "bold",
    borderRadius: "5px",
    marginRight: theme.spacing(1),
  },
  button2: {
    cursor: "pointer",
    background: "blue",
    border: "none",
    padding: "10px",
    color: "white",
    fontWeight: "bold",
    borderRadius: "5px",
  },
  lanesContainer: {
    display: "flex",
    flexDirection: "row",
    overflowX: "auto", 
    transition: "margin-left 0.3s ease, padding-left 0.3s ease",
  },
  column: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    minWidth: 300,
    position: "relative",
  },
  card: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[1],
  },
}));

const Kanban = () => {
  const { id } = useParams();
  const classes = useStyles();
  const history = useHistory();
  const { listNotes } = useTicketNotes();
  const { user } = useContext(AuthContext);

  const [kanbans, setKanbans] = useState([]);
  const [tags, setTags] = useState([]);
  const [reloadData, setReloadData] = useState(false);
  const [isInitialLoadComplete, setIsInitialLoadComplete] = useState(false);
  const [kanbanSelectModalOpen, setKanbanSelectModalOpen] = useState(false);
  const [kanbanMessageSendModalOpen, setKanbanMessageSendModalOpen] = useState(false);
  const [tagMessageSendModalOpen, setTagMessageSendModalOpen] = useState(false);
  const [ticketId, setTicketId] = useState(1);
  const [observationsModalOpen, setObservationsModalOpen] = useState(false);
  const [observations, setObservations] = useState([]);
  const [lanesContainerStyle, setLanesContainerStyle] = useState({});

  const handleOpenKanbanSelectModal = (id) => {
    setTicketId(id);
    setKanbanSelectModalOpen(true);
  };

  const handleCloseKanbanSelectModal = () => {
    setKanbanSelectModalOpen(false);
  };

  const handleOpenKanbanMessageSendModal = () => {
    setKanbanMessageSendModalOpen(true);
  };

  const handleCloseKanbanMessageSendModal = () => {
    setKanbanMessageSendModalOpen(false);
  };

  const handleOpenTagMessageSendModal = () => {
    setTagMessageSendModalOpen(true);
  };

  const handleCloseTagMessageSendModal = () => {
    setTagMessageSendModalOpen(false);
  };

  const fetchKanbans = useCallback(async () => {
    try {
      const { data } = await api.get("/kanbans/");
      setKanbans(data.kanbans);
    } catch (err) {
      toastError(err);
    }
  }, []);

  const fetchTags = async () => {
    try {
      const response = await api.get("/tags/kanban");
      const fetchedTags = response.data.lista || [];

      setTags(fetchedTags);

      await fetchTickets(jsonString);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchTags();
    fetchKanbans();
  }, []);

  const [columns, setColumns] = useState({});
  const [tickets, setTickets] = useState([]);
  const { profile, queues } = user;
  const jsonString = user.queues.map(queue => queue.UserQueue.queueId);

  const fetchTickets = async (jsonString) => {
    try {
      const { data } = await api.get("/ticket/kanban", {
        params: {
          kanbanid: id ?? 1,
          queueIds: JSON.stringify(jsonString),
          teste: true
        }
      });
      setTickets(data.tickets);
    } catch (err) {
      console.log(err);
      setTickets([]);
    }
  };

  const popularCards = (jsonString) => {
    const filteredTickets = tickets.filter(ticket => ticket.kanban_id == id);

    const lanes = {
      0: {
        id: "0",
        title: i18n.t("Em aberto"),
        cards: filteredTickets
          .filter(ticket => ticket.tags.length === 0)
          .map(ticket => ({
            id: ticket.id.toString(),
            title: "Ticket nº " + ticket.id.toString(),
            description: (
              <>
               <p>
  {ticket.contact.name ? (
    <>
      <strong>{ticket.contact.name}</strong> {" (" + ticket.contact.number + ")"}
    </>
  ) : (
    ticket.contact.number
  )}
  {" - crm: #" + ticket.kanban_id}
  <br />
  {ticket.lastMessage}
</p>
                <button
                  className={classes.button}
                  onClick={() => {
                    handleCardClick(ticket.uuid)
                  }}>
                  Ver Ticket
                </button>
                <button
                  className={classes.button2}
                  onClick={() => {
                    handleOpenKanbanSelectModal(ticket.id)
                  }}>
                  Migrar CRM
                </button>
                <EditNoteIcon
                  onClick={() => handleViewObservations(ticket.id)}
                  fontSize="small"
                  style={{
                    color: blue[700],
                    cursor: "pointer",
                    marginLeft: 10,
                    verticalAlign: "middle"
                  }}
                />
              </>
            ),
          })),
      },
      ...tags.reduce((acc, tag) => {
        const filteredTicketsByTag = filteredTickets.filter(ticket => {
          const tagIds = ticket.tags.map(tag => tag.id);
          return tagIds.includes(tag.id);
        });

        acc[tag.id] = {
          id: tag.id.toString(),
          title: tag.name,
          cards: filteredTicketsByTag.map(ticket => ({
            id: ticket.id.toString(),
            title: "Ticket nº " + ticket.id.toString(),
            description: (
              <>
              <p>
  {ticket.contact.name ? (
    <>
      <strong>{ticket.contact.name}</strong> {" (" + ticket.contact.number + ")"}
    </>
  ) : (
    ticket.contact.number
  )}
  {" - crm: #" + ticket.kanban_id}
  <br />
  {ticket.lastMessage}
</p>
                <button
                  className={classes.button}
                  onClick={() => {
                    handleCardClick(ticket.uuid)
                  }}>
                  Ver Ticket
                </button>
                <button
                  className={classes.button2}
                  onClick={() => {
                    handleOpenKanbanSelectModal(ticket.id)
                  }}>
                  Migrar CRM
                </button>
                <EditNoteIcon
                  onClick={() => handleViewObservations(ticket.id)}
                  fontSize="small"
                  style={{
                    color: blue[700],
                    cursor: "pointer",
                    marginLeft: 10,
                    verticalAlign: "middle"
                  }}
                />
              </>
            ),
          })),
          style: { backgroundColor: tag.color, color: "white" }
        };

        return acc;
      }, {}),
    };

    const sortedLaneKeys = Object.keys(lanes).sort((a, b) => {
      if (a === "lane0") return -1;
      if (b === "lane0") return 1;
      return a.localeCompare(b, undefined, { numeric: true });
    });

    const reversedLaneKeys = sortedLaneKeys.reverse();

    const orderedLanes = reversedLaneKeys.reduce((acc, key) => {
      acc[key] = lanes[key];
      return acc;
    }, {});

    setColumns(orderedLanes);
  };

  const handleCardClick = (uuid) => {
    history.push('/tickets/' + uuid);
  };

  const handleViewObservations = async (ticketId) => {
    try {
      const response = await api.get('/ticket-notes/');
      const data = response.data;

      if (Array.isArray(data.ticketNotes)) {
        const filteredNotes = data.ticketNotes.filter(note => note.ticketId === ticketId);
        setObservations(filteredNotes);
        setObservationsModalOpen(true);
      } else {
        setObservations([]);
      }
    } catch (err) {
      setObservations([]);
    }
  };

  const handleCloseObservationsModal = () => {
    setObservationsModalOpen(false);
  };

  useEffect(() => {
    popularCards(jsonString);
  }, [tags, tickets, reloadData]);

  const onDragEnd = async (result) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    const sourceColumn = columns[source.droppableId];
    const destColumn = columns[destination.droppableId];
    const sourceCards = [...sourceColumn.cards];
    const destCards = [...destColumn.cards];
    const [removed] = sourceCards.splice(source.index, 1);

    destCards.splice(destination.index, 0, removed);

    setColumns({
      ...columns,
      [sourceColumn.id]: {
        ...sourceColumn,
        cards: sourceCards
      },
      [destColumn.id]: {
        ...destColumn,
        cards: destCards
      }
    });

    try {
      await api.post('/update-ticket-tag', {
        sourceId: source.droppableId,
        destinationId: destination.droppableId,
        ticketId: removed.id
      });
      toast.success('Ticket tag updated successfully');
    } catch (error) {
      console.error("Error updating ticket tag:", error);
      toast.error('Failed to update ticket tag');
    }
  };

  // Adicionando listener para ajustar estilos baseado na resolução
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1336) {
        setLanesContainerStyle({ marginLeft: '500px' });
      } else {
        setLanesContainerStyle({ marginLeft: '28%' });
      }
      if (window.innerWidth <= 1666) {
        setLanesContainerStyle({ marginLeft: '3000px' });
      } else {
        setLanesContainerStyle({ marginLeft: '28%' });
      }

    };

    window.addEventListener("resize", handleResize);

    // Chame imediatamente para ajustar ao carregar a página
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.buttonContainer}>
        <Button 
          variant="contained" 
          color="primary" 
          onClick={handleOpenKanbanMessageSendModal}
          className={classes.button}
        >
          Disparar Mensagens por Kanban
        </Button>
        <Button 
          variant="contained" 
          color="secondary" 
          onClick={handleOpenTagMessageSendModal}
          className={classes.button2}
        >
          Disparar Mensagens por Tag
        </Button>
      </div>
      <div className={classes.lanesContainer} style={lanesContainerStyle}>
        <DragDropContext onDragEnd={onDragEnd}>
          {Object.entries(columns).map(([columnId, column], index) => (
            <Droppable key={columnId} droppableId={columnId}>
              {(provided, snapshot) => (
                <div
                  className={classes.column}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  <h2>{column.title}</h2>
                  {column.cards.map((card, index) => (
                    <Draggable key={card.id} draggableId={card.id} index={index}>
                      {(provided, snapshot) => (
                        <div
                          className={classes.card}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          {card.description}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          ))}
        </DragDropContext>
      </div>
      <KanbanSelectModal
        open={kanbanSelectModalOpen}
        onClose={handleCloseKanbanSelectModal}
        reload={fetchTickets}
        aria-labelledby="form-dialog-title"
        tagId={ticketId}
      />
      <ObservationsModal
        open={observationsModalOpen}
        onClose={handleCloseObservationsModal}
        observations={observations}
      />
      <KanbanMessageSendModal 
        open={kanbanMessageSendModalOpen} 
        onClose={handleCloseKanbanMessageSendModal} 
        kanbanId={id ?? 1} 
      />
      <TagMessageSendModal 
        open={tagMessageSendModalOpen} 
        onClose={handleCloseTagMessageSendModal} 
        tags={tags} 
        kanbanId={id ?? 1} 
      />
    </div>
  );
};

export default Kanban;

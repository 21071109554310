import React, { useState, useContext } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import { toast } from "react-toastify";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";

const KanbanMessageSendModal = ({ open, onClose, kanbanId }) => {
  const [message, setMessage] = useState('');
  const [contactNumbers, setContactNumbers] = useState([]);
  const { user } = useContext(AuthContext);

  const handleSendMessage = async () => {
    try {
      const { data } = await api.get("/ticket/kanban", {
        params: {
          kanbanid: kanbanId ?? 1,
          teste: true,
        }
      });

      const tickets = data.tickets.filter(ticket => ticket.kanban_id === kanbanId);

      tickets.forEach((ticket, index) => {
        setTimeout(async () => {
          const messageData = {
            read: 1,
            fromMe: true,
            mediaUrl: "",
            body: `*${user?.name}:*\n${message.trim()}`,
            quotedMsg: "",
          };

          console.log("Sending message to:", messageData);

          try {
            await api.post(`/messages/${ticket.id}`, messageData);
            toast.success(`Mensagem enviada para ticket #${ticket.id}`);
          } catch (err) {
            console.error(`Erro ao enviar mensagem para ticket #${ticket.id}:`, err.response ? err.response.data : err.message);
            toastError(err);
          }

          if (index === tickets.length - 1) {
            toast.success("Messages sent successfully!");
            onClose();
          }
        }, index * 20000); // 20 seconds interval
      });
      
    } catch (error) {
      console.error("Error sending messages:", error);
      toastError(error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>Disparo de Mensagens por Kanban</DialogTitle>
      <DialogContent>
        <TextField
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          label="Message"
          multiline
          rows={4}
          fullWidth
          margin="normal"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSendMessage} color="primary" variant="contained">Send</Button>
      </DialogActions>
      <DialogContent>
        {contactNumbers.length > 0 && (
          <div>
            <h3>Números dos Contatos:</h3>
            <ul>
              {contactNumbers.map((number, index) => (
                <li key={index}>{number}</li>
              ))}
            </ul>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default KanbanMessageSendModal;

import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@material-ui/core';

const ObservationsModal = ({ open, onClose, observations }) => {
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Observações</DialogTitle>
      <DialogContent>
        {observations.length > 0 ? (
          observations.map((note, index) => (
            <Typography key={index} variant="body1" gutterBottom>
              {note.note}
            </Typography>
          ))
        ) : (
          <Typography variant="body1">Nenhuma observação encontrada.</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ObservationsModal;
